/**
 * 分批获取大数据量服务结果
 * input  maxAckTime 最大超时时间（可作业传参）
 */
import { Observable } from "rxjs";
import { FxDapHttpClient } from "app/implementation/shared/fx-http-client/http/fx-http-client";
import { DwRoutingMessageService } from "@webdpt/framework";
import { TranslateService } from "@ngx-translate/core";
import * as _ from 'lodash';
import { Injectable, EventEmitter, Output } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
export class AckCycleService {
    public putGetAckStus: any;
    public maxAckTime = 180000; //服务执行最大超时时间
    public startTime; //开始时间
    public ackdocno = ''; //任务编号
    public doReaultFn;
    constructor(
        private http: FxDapHttpClient,
        public dwMessage: DwRoutingMessageService,
        private translateService: TranslateService
    ) { }

    //执行作业服务，并初始化ack服务执行记录
    put_add_ack(param): Observable<any> {
        return new Observable((observer) => {
            this.http.post('A/IACommonService/put_add_ack', {
                param: param
            }).subscribe(result => {
                if (result.success) {
                    observer.next(result);
                } else {
                    this.dwMessage.error(this.translateService.instant('comm-httperror')); //资源请求失败，请稍后重试
                    observer.next(null);
                }
            }, error => {
                this.dwMessage.error(this.translateService.instant('comm-httperror')); //资源请求失败，请稍后重试
                observer.next(null);
            });
        });
    };

    //周期获取服务执行结果
    cyclePutGetAck(res): void {
        if (!(res && res.success && res.data && res.data.ackuid)) { return; }
        this.ackdocno = res.data.ackuid;
        setTimeout(() => {
            this.put_get_ack(this);
            this.putGetAckStus = setInterval(this.put_get_ack, 3000, this);
        }, 1000);

    }

    //获取服务执行结果
    put_get_ack(parentThis): void {
        if ((new Date().getTime()) - parentThis.startTime > parentThis.maxAckTime) { //超时
            console.log('超时');
            clearInterval(parentThis.putGetAckStus); //清除周期执行触发器
            parentThis.dwMessage.error(parentThis.translateService.instant('comm-timeout')); //资源请求执行超时，请稍后重试
        } else {
            parentThis.http.post('A/IACommonService/put_get_ack', {
                param: { id: parentThis.ackdocno, ack001: 'fxgp001' }
            }).subscribe(result => {
                if (result && result.success && result.data.ackstus === 'Y') {
                    console.log('获取到结果');
                    clearInterval(parentThis.putGetAckStus); //清除周期执行触发器
                    var response = JSON.parse(result.data.acknote); //结算服务返回结果
                    parentThis.doReaultFn(response);
                }
            }, error => {
                clearInterval(parentThis.putGetAckStus); //清除周期执行触发器
                parentThis.dwMessage.error(parentThis.translateService.instant('comm-httperror')); //资源请求失败，请稍后重试
            })
        }
    }
}