import { Injectable, EventEmitter } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class HomeStyleService {
  
  public eventEmit: any;
  configEndPoint = 'assets/map/china.json';
  cityUrl = 'assets/map/china-cities.json';
  public geoCoordMap = [];
  constructor(private http: Http) { 
    // 定义发射事件
    this.eventEmit = new EventEmitter();
  }

  //获取地图坐标
  getMapCoord() {
    return this.http
      .get(this.configEndPoint)
      .pipe(map(data => data.json()));
  }

  getCity() {
    return this.http
    .get(this.cityUrl)
    .pipe(map(data => data.json()));
  }
}
