import { GridApi } from "ag-grid-community";
import { RowNode } from "ag-grid-community";

/**
 * 获取grid所有RowNode数据
 * @param gridApi grid对应API
 * @param currentEditingId grid当前正在编辑行ID
 * @param isFilter 获取数据是否先取消过滤,默认true  true/false
 * @returns RowNode[] 返回所以数据RowNode
 */
export function getAllRowNodeData(gridApi: GridApi, currentEditingId, isFilter = true): RowNode[] {
  if (!gridApi) { //grid未生成
    return [];
  }
  var allData = [];
  var nowNode: any;
  if (currentEditingId === null || currentEditingId === undefined || currentEditingId === '') {
    gridApi.forEachNode((obj, index) => {   
      allData.push(obj);
    });
  } else {
    gridApi.forEachNode((obj, index) => {    
      if (obj.id == currentEditingId.toString()) {
        if (isFilter) {
          gridApi.setFilterModel(null);
        }
        nowNode = gridApi.getEditingRowNode(true).filter(obj =>{
          return obj.id == currentEditingId.toString();
        })[0];
        nowNode = nowNode ? nowNode : obj;
        allData.push(nowNode);
      } else {
        allData.push(obj);
      }
    });
  }
  return allData;
}

/**
 * 根据id在数据源中查找到id所在的rowNode
 * @param datas 数据源
 * @param id 当前id
 * @returns 返回当前id对应的rowNode
 */
export function getCurrentRowNode(datas, id): RowNode{
  return datas.find(item => item.id == id);
}
